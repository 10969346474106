html {
  background-color: #f5f7f9;
  --primary-background-color: #fafafa;
  --primary-text-color: #222;
  --border-color: #e1e4e8;
  --button-background-color: #fff;
  --button-disabled-color: #757575;
  --button-disabled-border-color: #bdbdbd;
  --button-border-color: rgb(230, 236, 240);
  --button-focus-color: rgb(71, 70, 70);
  --scrollbar-background-color: rgba(113, 122, 116, 0.4);
  --card-background-color: #f5f7f9;
  --color-text-secondary: #586069;
  --blue-background: #d2e3fc;
  --blue-color: #1da1f2;
  --twit-color: #1da1f2;
  --twit-background: hsla(203, 89%, 53%, 0.1);
  --purple-background: #ffaabf;
  --white-color: white;
  --dark-background: black;
  --green-color: #2da44e;
  --green-background: rgba(23, 191, 99, 0.1);
  --focus-background: rgba(0, 0, 0, 0.06);
}

body {
  font-family: Segoe UI, system-ui, -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  color: var(--primary-text-color);
  font-size: 15px;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}

html,
body,
main {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

p {
  font-size: 15px;
  font-weight: 500;
}

h2,
h3 {
  font-weight: 700;
}

h4,
p {
  line-height: 1.5;
  padding: 0;
  margin: 0;
}

h2 {
  background: linear-gradient(
    141.27deg,
    #ff904e -4.24%,
    #ff5982 21.25%,
    #ec68f4 44.33%,
    #79e2ff 83.46%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 25px;
}

a {
  color: rgb(23, 191, 99);
  text-decoration: none;
  font-weight: 600 !important;
}

a:active {
  color: var(--green-color);
  font-weight: 600;
}

a:hover {
  color: var(--green-color);
  font-weight: 600;
}

li {
  margin: 15px 0;
}

.name {
  font-size: 4rem;
  line-height: 1.1;
  color: #fa3768;
  letter-spacing: -0.04em;
  background: linear-gradient(
    141.27deg,
    #ff904e -4.24%,
    #ff5982 21.25%,
    #ec68f4 44.33%,
    #79e2ff 83.46%
  );
  background-size: 200%;
  -webkit-background-clip: text;
  background-clip: text;
  animation: gradient-data-v-fb6d3afe 10s ease infinite;
  -webkit-text-fill-color: transparent;
}

.about-info {
  font-size: 13px;
  margin-top: 5px;
  text-decoration: underline;
}

.bio {
  line-height: 1.5;
  margin: 10px auto;
  font-size: 25px;
  margin-top: 0;
  max-width: 450px;
  padding: 0 15px;
}

.no-results {
  margin: 20px auto;
  text-align: center;
  font-size: 20px;
}

.wallpaper {
  background-image: url("./assets/images/wallpaper.jpeg");
  height: 250px;
  width: 100%;
  background-size: cover;
  object-fit: cover;
  text-align: center;
}

.display-photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  left: 0;
  right: 0;
  top: 150px;
  border: 4px solid #fff;
}

.bio-section {
  top: 100px;
  position: relative;
  text-align: center;
}

.navigate {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--dark-background);
}

.navigate:link,
.navigate:visited {
  text-decoration: none;
  border-bottom: 3px solid black;
  color: var(--dark-background);
  transition: background 0.2s linear;
  padding-bottom: 3px;
}

.navigate:hover {
  background: black;
  padding: 10px 15px;
  color: white;
}

.explore-link {
  font-size: 18px;
  line-height: 1.5;
}

.talk-component {
  /* background-color: #f0f2f5; */
  padding: 20px;
  font-size: 14px;
  border-bottom: 1px solid var(--border-color);
}

.talk-component:hover {
  cursor: pointer;
  background-color: #f0f2f5;
}

.card:hover {
  cursor: pointer;
  background-color: #f0f2f5;
}

.talk-title {
  line-height: 1.5;
  font-size: 19px;
  font-weight: 600;
  margin: 10px 0;
  margin-bottom: 5px;
}

.talk-desc {
  line-height: 1.5;
  color: rgb(103, 120, 138);
  font-size: 15px;
}

.toggle-icon {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  border: 1px solid var(--border-color);
  cursor: pointer;
  z-index: 1000;
  margin-right: 15px;
  font-weight: 600;
}

.text-gradient {
  background: linear-gradient(
    141.27deg,
    #ff904e -4.24%,
    #ff5982 21.25%,
    #ec68f4 44.33%,
    #79e2ff 83.46%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.maye-ads {
  background-color: #fff;
  position: relative;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border: 0;
  border-bottom: 1px solid var(--border-color);
}

.s ocial-maye {
  color: var(--blue-color) !important;
}

.post p {
  margin: 10px 0;
}

.home {
  background-color: #f5f7f9;
  display: flex;
}

.home-title {
  padding: 0px 30px;
  padding-bottom: 22px;
  border-bottom: 1px solid var(--border-color);
}

.skills {
  color: var(--color-text-secondary);
  font-size: 13px;
  margin-top: 0;
}

.left {
  width: 100%;
  max-width: 300px;
  min-height: 100vh;
  z-index: 2;
}

.right {
  border: 0;
  width: 100%;
}

.nav-right {
  margin: 0 auto;
  float: right;
  display: inline-flex;
}

.inner-nav {
  background-color: #f5f7f9;
  border: 0;
  border-right: 1px solid rgb(230, 236, 240);
  display: flex;
  flex-direction: column;
  bottom: 0;
  max-width: 300px;
  width: 100%;
  position: fixed;
  height: 100%;
}

.nav-items {
  /* padding-top: 30%; */
  height: 100%;
}

.nav-item {
  color: var(--color-text-secondary);
  display: block;
  font-size: 25px;
  font-weight: 600;
  margin: 0;
  padding: 30px;
  border-bottom: 1px solid var(--border-color);
}

.nav-item:hover {
  color: var(--green-color);
}

.text-gradient {
  background: linear-gradient(
    270.97deg,
    #ffe580 -21.36%,
    #ff7571 -2.45%,
    #ea5dad 26.84%,
    #c2a0fd 64.15%,
    #3bf0e4 108.29%,
    #b2f4b6 159.03%
  );
  -webkit-text-fill-color: transparent;
}

.active {
  color: var(--green-color);
}

.intro {
  background-color: #f5f7f9;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  bottom: 0;
  margin: 0 auto;
  overflow: auto;
  align-content: center;
  text-align: center;
}

.about-maye {
  text-align: left;
  margin: 75px auto;
}

.about-maye img {
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  width: 250px;
}

.more {
  display: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: var(--blue-color);
  cursor: pointer;
  text-align: center;
  padding-left: 20px;
}

.more:focus {
  outline: 0;
  background-color: #fff;
}

.more:hover {
  outline: 0;
}

.underline-from-left {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow: hidden;
}

.underline-from-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: var(--blue-color);
  height: 3px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.underline-from-left:hover:before {
  right: 0;
}

.social {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}

.social-link {
  padding: 10px 30px;
  display: flex;
  align-items: center;
}

.social-blurb {
  display: flex;
  align-items: center;
  padding: 20px 30px;
  color: rgb(106, 115, 125);
}

.social-blurb,
.social-link {
  border: 0;
  border-bottom: 1px solid var(--border-color);

  line-height: 1.5;
}

time {
  font-size: 13px;
}

.flash {
  position: relative;
  padding: 16px;
  color: #032f62;
  background-color: #dbedff;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 3px;
  font-size: 15px;
  line-height: 1.5;
}

button,
input {
  overflow: visible;
}

button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
}

.flash-action {
  float: right;
  margin-top: -3px;
  margin-left: 24px;
}

.btn-sm {
  padding: 3px 10px;
  font-size: 12px;
  line-height: 20px;
}

.btn {
  color: #24292e;
  background-color: #eff3f6;
  background-image: linear-gradient(-180deg, #fafbfc 0%, #eff3f6 90%);
  position: relative;
  display: inline-block;
  padding: 6px 12px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-repeat: repeat-x;
  background-position: -1px -1px;
  background-size: 110% 110%;
  border: 1px solid rgba(27, 31, 35, 0.2);
  border-radius: 0.25em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.marker {
  background-color: #999999;
  border-radius: 50%;
  content: "";
  height: 12px;
  margin: 0 6px;
  margin-left: 0;
  position: relative;
  transition: all ease 250ms;
  width: 12px;
}

.tag-marker {
  font-size: 10px !important;
  border-radius: 5px;
  padding: 2px 5px;
  display: inline-block;
  transition: all ease 250ms;
  text-transform: uppercase;
  background-color: black;
  color: #fff;
  font-weight: 600;
}

.tag-marker-article {
  font-size: 12px !important;
  color: #202124 !important;
  display: inline-block;
  padding: 4px 0;
  -webkit-transition: all ease 250ms;
  transition: all ease 250ms;
}

pre.line-numbers {
  text-size-adjust: none;
  color: #333;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  tab-size: 4;
  hyphens: none;
  padding: 1em;
  overflow: auto;
  background: #f5f2f0;
  counter-reset: d;
  font: 15px/17px Consolas, Lucida Console, Menlo, Monaco, monospace;
  z-index: 0;
  text-shadow: none;
  margin: 0;
  position: relative;
  padding-left: 3.5em;
  margin-bottom: 0;
}

.mobile {
  display: none;
}

::-webkit-scrollbar {
  width: 3px;
}

/* track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 0;
  border-radius: 0;
}

/* handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0;
  border-radius: 0;
  background: var(--scrollbar-background-color);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: none;
}

@media (max-width: 799px) {
  .toggle-icon {
    display: flex;
  }
  main {
    border: 0;
  }
  .skills {
    display: none;
  }
  body {
    margin: 0;
  }
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .inner-nav,
  .left {
    width: 100%;
    max-width: 100%;
  }

  .mobile-nav {
    text-decoration: none;
    border-bottom: 4px solid var(--dark-background);
    color: var(--dark-background);
    transition: background 0.2s linear;
    padding-bottom: 5px;
    width: fit-content;
  }

  .left {
    display: none;
  }

  .right {
    border: 0;
    width: 100%;
  }

  .more {
    display: inline;
  }
  #carbonads {
    margin-top: 15px !important;
  }
}
