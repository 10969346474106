.home-page {
  line-height: 1.5;
  margin: 0 auto;
  margin-top: 50px;
}

.home-box-grid-container,
.home-page-top {
  margin: 0 auto;
  padding: 20px;
  max-width: 840px;
  width: 100%;
}

.home-box-grid {
  background-color: #ddd;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1px;
  padding: 0;
  margin: 0 auto;
  max-width: 800px;
}

.home-box-grid-item {
  background-color: #fff;
  cursor: pointer;
  padding: 20px;
  transition: all 0.3s ease-in-out;
  max-width: 400px;
  width: 100%;
}

.home-box-grid-item-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  gap: 1px;
  display: flex;
}

.home-box-grid-item-desc {
  color: #57606a;
  font-size: 1.2rem;
  margin: 0;
}

.home-box-grid-item:hover .home-box-grid-item-desc {
  color: #2da44e;
}

.home-page-title,
.home-verified,
.home-official {
  display: flex;
  align-items: center;
  gap: 5px;
}

.home-page-title {
  gap: 8px;
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.home-verified {
  cursor: pointer;
  justify-content: center;
  margin-top: 3px;
}

.home-verified-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.home-official {
  gap: 3px;
  margin: 0;
  padding: 0;
  margin-top: -5px;
}

.home-official-label {
  font-size: 13px;
  color: #57606a;
}

.home-page-desc {
  color: #57606a;
  font-size: 1.5rem;
  margin: 0;
}

.home-page-intro {
  padding: 20px 0;
  display: flex;
  align-items: center;
  gap: 25px;
  margin-bottom: 10px;
}

.home-page-desc-text {
  font-size: 20px;
}

.home-page-intro-avatar {
  border-radius: 50%;
  width: 145px;
}

.home-box-details {
  background-color: #fff;
  border-radius: 5px;
  max-width: 800px;
  width: 100%;
}

.home-box-details-title {
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 21px;
  grid-gap: 3px;
  gap: 5px;
  margin: 0 auto;
  padding: 17px 20px;
}

.home-box-details-title:hover {
  color: #2da44e;
}

.home-box-details-desc {
  padding: 15px 20px;
  font-size: 17.5px;
}

.home-box-details-footer {
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  border-top: 1px solid #eaeaea;
}

.home-box-details-footer-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 15px 20px;
  flex: 1;
}

.home-page-top {
  padding: 20px 0;
}

@media screen and (max-width: 450px) {
  .home-page {
    margin-top: 0;
  }

  .home-box-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .home-page-intro {
    flex-direction: column;
    align-items: baseline;
    gap: 10px;
  }
}
