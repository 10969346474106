html {
  font-size: 14px;
  --dark-color: #222;
  --dark-background: #ddd;
  --blue-color: rgb(29, 161, 242);
  --blue-background: hsla(203, 89%, 53%, 0.1);
}

li {
  margin: 10px 0;
}

.page-parent {
  margin: 0 auto;
  border: 0;
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}

.article-page {
  margin: 0 auto;
  border: 0;
}

.docs p,
li,
h2,
h3,
h4 {
  line-height: 1.5;
  margin: 15px 0;
  padding: 0;
}

.article-banner {
  background-color: #f0f2f5;
  position: relative;
  z-index: 2;
  padding: 80px 0;
  margin: 0 auto;
  border-bottom: 1px solid var(--border-color);
}

.article-banner-title {
  margin: 0 auto;
  text-align: center;
  font-weight: 700;
  font-size: 35px;
  max-width: 600px;
}

.article-date {
  color: #000;
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
}

.article-date span {
  display: flex;
  align-items: center;
  justify-content: center;
}

blockquote {
  margin: 0 auto;
  background-color: #f0f2f5;
  padding: 0px 20px;
  font-size: 14px;
  margin: 15px auto;
  border-radius: 5px;
  border: 1px solid var(--border-color);
}

img {
  width: 100%;
}

h4 {
  font-size: 17px;
  font-weight: 600;
}

h3 {
  border: none;
  font-size: 23px;
  width: -moz-fit-content;
  width: fit-content;
  width: intrinsic;
  width: -moz-max-content;
  z-index: 0;
  /* background: linear-gradient(
    141.27deg,
    #ff904e -4.24%,
    #ff5982 21.25%,
    #ec68f4 44.33%,
    #79e2ff 83.46%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.top-items {
  margin: 0 auto;
  display: flex;
  align-items: center;
  width: 100%;
}

.topics {
  float: right;
  text-transform: lowercase;
}

.purple {
  background-color: var(--purple-background);
}

.announce {
  background: #e0f2f1;
  color: #00796b;
  padding: 10px;
  font-size: 15px;
  border-left: 5px solid #00796b;
}

#close {
  height: 25px;
  width: 25px;
  text-align: center;
  float: right;
  border-radius: 50%;
  margin-bottom: 5px;
  font-size: 15px !important;
}

#close:hover {
  display: inline-block;
  color: #fff;
}

figure {
  margin: 0 auto;
}

figcaption {
  background: #e0f2f1;
  color: #00796b;
  text-align: center;
  padding: 5px;
  font-size: 12px;
}

.article-section {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  top: 0;
  right: 0;
  left: 0;
  overflow-y: auto;
}

.post {
  display: block;
  max-width: 850px;
  margin: 5px auto;
  padding: 0 15px;
  z-index: 1000;
  background: #fff;
}

.fix {
  margin: 60px auto;
}

.quote {
  background-color: #f0f2f5;
  padding: 10px 20px;
  font-size: 14px;
  margin: 15px auto;
  border-radius: 5px;
  border: 1px solid var(--border-color);
}

.highlight {
  background: #e0f2f1;
  color: #00796b;
  padding: 10px;
  font-size: 14px;
  border-left: 5px solid #00796b;
}

.highlight a {
  color: #00796b !important;
  text-decoration: underline;
}

.date {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.date-text {
  text-transform: uppercase;
  background: linear-gradient(
    141.27deg,
    #ff904e -4.24%,
    #ff5982 21.25%,
    #ec68f4 44.33%,
    #79e2ff 83.46%
  );
  -webkit-background-clip: text;
  line-height: 1.5;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.1rem;
  font-size: 13px;
  font-weight: 600;
}

.post2 {
  margin-top: 20px;
}

.navigation {
  margin: 0 auto;
  width: 100px;
  text-align: center;
  border-bottom: 1px solid var(--blue-color);
  padding-bottom: 7px;
}

.bold {
  border-left: 3px solid var(--blue-color);
  padding-left: 10px;
  color: var(--blue-color);
}

.cut {
  text-decoration: line-through;
}

#progress {
  position: absolute;
  background: var(--blue-color);
  height: 100vh;
  width: 0;
  transition: width 0.2s ease-in-out;
}

.center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyButton {
  border: 0px solid #00796b;
  background: #e0f2f1;
  color: #00796b;
  padding: 5px;
  width: 50px;
  float: right;
  border-radius: 6px;
}

.copyButton:active {
  box-shadow: none;
  border-radius: 6px;
}

.hide {
  display: none;
}

.aboard-message {
  padding: 10px;
  border-radius: 3px;
  border: 1px solid var(--border-color);
  font-size: 15px;
}

.tweets-social {
  display: grid;
  grid-template-columns: 290px 290px;
  grid-gap: 20px;
  margin: 0 auto;
  width: fit-content;
}

@media (min-width: 800px) {
  .article-section {
    top: 0px;
    bottom: 0px;
  }

  .post {
    /* border: 1px solid #e8eaed; */
    margin: 30px auto;
    padding: 30px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  }
}

@media (max-width: 736px) {
  .article-banner {
    padding: 30px 10px;
  }

  .article-banner-title {
    font-size: 30px;
  }

  .article-date {
    font-size: 14px;
  }
}
