.articles {
  margin: 0 auto;
  padding: 0;
  line-height: 1.5;
}

.card-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0;
}

.card-title-text {
  font-weight: 600;
  font-size: 22px;
  /* background: linear-gradient(
    270.97deg,
    #ffe580 -21.36%,
    #ff7571 -2.45%,
    #ea5dad 26.84%,
    #c2a0fd 64.15%,
    #3bf0e4 108.29%,
    #b2f4b6 159.03%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.card-desc {
  line-height: 1.5;
  color: rgb(103, 120, 138);
  margin: 8px auto;
  font-size: 15px;
}

.card-date {
  margin-left: 6px;
  font-size: 13px;
}

.card-footer {
  padding: 10px 0;
}

.card {
  border-bottom: 1px solid var(--border-color);
  padding: 10px 20px;
  margin: 0 auto;
}

@media (max-width: 736px) {
}
