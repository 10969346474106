html {
  --text-color: black;
  --title-background: #e5e6f4;
  --box-shadow: #aec4e5;
}

#carbonads {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  line-height: 1.5;
  max-width: 400px;
  margin-top: 38px;
}

#carbonads img {
  border-radius: 5px;
  width: 60px;
  height: 60px;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

#carbonads > span {
  border: 1px solid #e1e8ed;
  position: relative;
  display: block;
  border-radius: 4px;
  background-color: #fff;
  text-align: left;
  font-size: 13px;
  font-weight: 500;
}

.carbon-wrap {
  display: flex;
  border-bottom: 1px solid var(--border-color);
  padding: 5px;
}

#carbonads a {
  color: #222;
  margin: 0 10px;
}
