.search-container {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border: 0;
  padding: 8px 20px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
}

.search-icon {
  padding: 0;
  margin: 0;
  margin-right: 8px;
  cursor: pointer;
}

.search-input {
  outline: 0;
  border: 0;
  background: transparent;
  font-weight: 400;
  font-size: 13px;
}
