.top-nav {
  background-color: #f5f7f9;
  display: block;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 15px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  bottom: 0;
  z-index: 2;
  border: 0;
  border-top: 1px solid var(--border-color);
  padding: 15px 18px;
}

.back {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.top-items {
  margin: 0 auto;
  font-weight: 700;
}

.topics {
  float: right;
  display: none;
}

@media (min-width: 800px) {
  .top-nav {
    box-shadow: rgb(17 12 46 / 15%) 0px 48px 100px 0px;
    top: 0;
    position: sticky;
    max-width: 600px;
    margin: 0 auto;
    bottom: 20px;
    z-index: 5;
    border-radius: 10px;
    border: 1px solid var(--border-color);
    width: 100%;
  }
  .topics  {
    display: block;
  }
}

@media (max-width: 786px) {
  .top-nav {
    background-color: #fff;
    border-right: 0;
  }
}
